import { Chart } from 'highcharts';
import { IS_PROD, VITE_ASSET_CDN_URL, VITE_IMAGE_CDN_URL } from './environment';

export function formatNumberToUSLocaleString(value: number | null | undefined, ...args: Parameters<number['toLocaleString']>[1][]): string {
    if (value == null) {
        return '0';
    }

    return value.toLocaleString('en-US', ...args);
}

export function currencyFormat(value: number, isBigNumber = false, sign = window.currency.sign) {
    const convertedValue: number = value * window.currency.price;

    switch (true) {
        case convertedValue >= 1000000000000 && isBigNumber:
            return `${sign}${(convertedValue / 1000000000000)
                .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                .replace(/\.0$/, '')}T`;
        case convertedValue >= 1000000000 && isBigNumber:
            return `${sign}${(convertedValue / 1000000000)
                .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                .replace(/\.0$/, '')}B`;
        case convertedValue >= 1000000 && isBigNumber:
            return `${sign}${(convertedValue / 1000000)
                .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                .replace(/\.0$/, '')}M`;
        case convertedValue >= 1000 && isBigNumber:
            return `${sign}${(convertedValue / 1000)
                .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                .replace(/\.0$/, '')}K`;
        default:
            return sign + convertedValue.toLocaleString('en-US', { minimumFractionDigits: 2 });
    }
}

export function priceFormat(
    value: number,
    isBigNumber = false,
    noShort = false,
) {
    const { sign } = window.currency;
    let short = false;
    let decimals = 2;

    if (value <= 0) {
        const newValue = 0;

        return `${sign}${newValue.toLocaleString('en-US')}`;
    }

    const convertedValue = value * window.currency.price;
    switch (true) {
        case convertedValue < 0.0000000000001:
            if (noShort) {
                decimals = 0;
            } else {
                short = true;
            }
            break;
        case convertedValue < 0.0000000001:
            decimals = 13;
            break;
        case convertedValue < 0.000000001:
            decimals = 12;
            break;
        case convertedValue < 0.00000001:
            decimals = 11;
            break;
        case value < 0.0000001:
            decimals = 10;
            break;
        case convertedValue < 0.000001:
            decimals = 9;
            break;
        case convertedValue < 0.00001:
            decimals = 8;
            break;
        case convertedValue < 0.0001:
            decimals = 7;
            break;
        case convertedValue < 2:
            decimals = 4;
            break;
        case convertedValue >= 1000 && isBigNumber:
            return currencyFormat(convertedValue, isBigNumber);
        default:
            decimals = 2;
            break;
    }

    if (short) {
        return `<${sign}${0.0000000000001.toLocaleString('en-US', { minimumFractionDigits: 13 })}`;
    }

    return `${sign}${convertedValue.toLocaleString('en-US', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    })}`;
}

export function numberFormat(value: number) {
    switch (true) {
        case value < 0.000000000001:
            return value.toFixed(13);
        case value < 0.00000000001:
            return value.toFixed(12);
        case value < 0.0000000001:
            return value.toFixed(11);
        case value < 0.000000001:
            return value.toFixed(10);
        case value < 0.00000001:
            return value.toFixed(9);
        case value < 0.0000001:
            return value.toFixed(8);
        case value < 0.000001:
            return value.toFixed(7);
        case value < 0.00001:
            return value.toFixed(6);
        case value < 0.0001:
            return value.toFixed(5);
        case value < 2:
            return value.toFixed(4);
        default:
            return value.toFixed(2);
    }
}

export function bigNumberFormat(value: number) {
    if (!value) {
        return value;
    }

    switch (true) {
        case value >= 1000000000000:
            return `${(value / 1000000000000)
                .toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
                .replace(/\.00/, '')}T`;
        case value >= 1000000000:
            return `${(value / 1000000000)
                .toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
                .replace(/\.00/, '')}B`;
        case value >= 1000000:
            return `${(value / 1000000)
                .toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
                .replace(/\.00/, '')}M`;
        case value >= 1000:
            return `${(value / 1000)
                .toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
                .replace(/\.00/, '')}K`;
        default:
            return value.toLocaleString('en-US', { minimumFractionDigits: 0 });
    }
}

export function numberFormatWithoutAbbreviation(value: number) {
   return `${(value)
       .toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
       .replace(/\.00/, '');
}

export function asset_cdn(path: string, width: number | null = null, height: number | null = null): string | null {
    let imagePath = path || '';

    if (!imagePath.trim()) {
        return null;
    }

    if (imagePath.startsWith(VITE_ASSET_CDN_URL)) {
        imagePath = imagePath.replace(VITE_ASSET_CDN_URL, '');
    }

    if (imagePath.startsWith(VITE_IMAGE_CDN_URL)) {
        imagePath = imagePath.replace(VITE_IMAGE_CDN_URL, '');
    }

    if (imagePath[0] !== '/') {
        imagePath = `/${imagePath}`;
    }

    if (IS_PROD) {
        const parameters: string[] = [];

        if (width) {
            parameters.push(`w-${width}`);
        }

        if (height) {
            parameters.push(`h-${height}`);
        }

        const parameterString = parameters.join(',');
        if (parameterString === '') {
            return `${VITE_ASSET_CDN_URL}${imagePath}`;
        }

        return `${VITE_ASSET_CDN_URL}${imagePath}?tr=${parameterString}`;
    }

    return `${VITE_IMAGE_CDN_URL}${imagePath}`;
}

export function isLightThemeEnabled() {
    const cookiesData = `; ${document.cookie}`;
    const cookieMatcher = /light-theme=(.+)/;
    const cookies = cookiesData.split(';');
    const cookie = cookies.find((cookie) => cookie.match(cookieMatcher));
    const cookieValue = cookie ? cookie.split('=')[1] : null;

    return cookieValue === 'enabled';
}

export function addChartWatermark(hcInstance: Chart | null): string | null {
    const isLightTheme = isLightThemeEnabled();
    const watermark: string = isLightTheme ? 'bitdegree-watermark.png' : 'bitdegree-watermark-inverted.png';

    if (!hcInstance) {
        return asset_cdn(`/crypto-prices/assets/${watermark}`);
    }

    const chartChildNodes: NodeList = hcInstance.renderer.box.childNodes;
    const childNode4Attributes = <NamedNodeMap>(<Element>chartChildNodes[4]).attributes;
    const childNode5Attributes = <NamedNodeMap>(<Element>chartChildNodes[5]).attributes;

    childNode4Attributes[5].nodeValue = asset_cdn(`/crypto-prices/assets/${watermark}`);
    childNode5Attributes[5].nodeValue = asset_cdn(`/crypto-prices/assets/${watermark}`);

    return null;
}

export function route(name: string) {
    return window.staticRouteUrls[name];
}

export function millisecondsToSeconds(ms: number) {
    return Math.floor(ms / 1000);
}
